import { ThemeType, theme } from ".";
import tailwindConfig from "../../../tailwind.config";

export function modernTheme(
  colors: any,
  isRTL: boolean,
  fonts: any
): ThemeType {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...colors,
      backgroundReverse: tailwindConfig.theme.extend.colors.gray[25],
    },
    fonts,
    isRTL,
  };
}
