import { FC, HTMLProps } from "react";

interface DeleteProps extends HTMLProps<SVGSVGElement> {
  small?: boolean;
}

const Delete: FC<DeleteProps> = ({ small, ...props }) => {
  return (
    <svg
      width={small ? 12 : 18}
      height={small ? 12 : 18}
      {...props}
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M1.99.99c-.407 0-.773.247-.926.624-.153.377-.063.81.229 1.093L7.586 9l-6.293 6.293c-.261.25-.367.623-.275.974.091.35.365.624.715.715.35.092.723-.014.974-.275L9 10.414l6.293 6.293c.25.261.623.367.974.275.35-.091.624-.365.715-.715.092-.35-.014-.723-.275-.974L10.414 9l6.293-6.293c.296-.287.385-.727.224-1.107-.16-.38-.538-.622-.95-.61-.26.008-.507.117-.688.303L9 7.586 2.707 1.293C2.519 1.099 2.26.99 1.99.99z"
      />
    </svg>
  );
};

export { Delete };
