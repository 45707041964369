import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { Delete } from "@/assets/Icons/Delete";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import { ZIndex } from "../types";
import { useIsBrowser } from "@/hooks/browser-only";

const ModalWidth = {
  large: "95%",
  medium: "50%",
  small: "28%",
};

const desktopModalTransitionStyles = {
  entering: { transform: "translateY(-5rem)", opacity: 0 },
  entered: { transform: "translateY(0)", opacity: 1 },
  exiting: { transform: "translateY(-5rem)", opacity: 0 },
  exited: { transform: "translateY(-5rem)", opacity: 0 },
};

const mobileModalTransitionStyles = {
  entering: { transform: "translateY(5rem)", opacity: 0 },
  entered: { transform: "translateY(0)", opacity: 1 },
  exiting: { transform: "translateY(5rem)", opacity: 0 },
  exited: { transform: "translateY(5rem)", opacity: 0 },
};

function getModalTransition(isMobile: boolean, transitionState) {
  return isMobile
    ? {
        ...mobileModalTransitionStyles[transitionState],
      }
    : {
        ...desktopModalTransitionStyles[transitionState],
      };
}

const backdropTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export type ModalWidthType = keyof typeof ModalWidth;

export interface ModalProps {
  show: boolean;
  onClose: any;
  modalWidth?: ModalWidthType;
  noTransition?: boolean;
  closeOnEscape?: boolean;
  overflow?: boolean;
  children?: React.ReactNode;
  closeIcon?: React.ReactNode;
  closeButtonPosition?: {
    top?: string;
    rightOrLeft?: string;
  };
  overLayColor?: string;
  className?: string;
  radius?: React.CSSProperties["borderRadius"];
}
export interface headerProps {
  children?: React.ReactNode;
  boxShadow?: string;
}
const Backdrop = styled.div<{ duration: number; overLayColor: string }>`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex["MODAL"]};
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
  box-sizing: border-box;
  ${({ duration }) => `  transition: all ${duration}ms ease-in-out`};
  ${({ overLayColor }) => `  background-color:  ${overLayColor}`};
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: ${ZIndex["MODAL"]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
`;

const ModalContainer = styled.div<{
  modalWidth: ModalWidthType;
  duration: number;
  isOverflow: boolean;
  radius?: React.CSSProperties["borderRadius"];
}>`
  box-sizing: border-box;
  position: fixed;
  max-height: 95%;
  max-width: 1060px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: ${({ radius }) => radius || "5px"};
  top: 50%;
  transform: translateY(-50%) !important;
  width: ${({ modalWidth }) => ModalWidth[modalWidth]};
  ${({ duration }) => `  transition: all ${duration}ms ease-in-out`};
  ${({ isOverflow }) => isOverflow && `overflow:visible`};

  .ModalBody {
    overflow-y: ${({ isOverflow }) => (isOverflow ? "visible" : "auto")};
  }

  @media (max-width: 1024px) {
    min-width: ${ModalWidth["medium"]};
  }
`;

const CloseContainer = styled.div<{
  closeButtonPosition?: ModalProps["closeButtonPosition"];
}>`
  display: flex;
  position: absolute;
  top: ${({ closeButtonPosition }) => closeButtonPosition?.top || "20px"};
  right: ${({ closeButtonPosition, theme }) =>
    theme.isRTL ? "unset" : closeButtonPosition?.rightOrLeft || "20px"};
  left: ${({ closeButtonPosition, theme }) =>
    theme.isRTL ? closeButtonPosition?.rightOrLeft || "20px" : "unset"};

  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
`;

const ModalTitle = styled.div<{
  boxShadow: React.CSSProperties["boxShadow"];
}>`
  padding: 16px 20px;
  box-shadow: ${({ boxShadow }) => boxShadow ?? "inset 0 -1px 0 0 #dfe3e8"};
  min-height: 52px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const ModalBody = styled.div<{
  padding: React.CSSProperties["padding"];
}>`
  padding: ${({ padding }) => padding || "20px"};
  /* max-height: 70vh; */
  /* overflow-y: auto; */
`;

const ModalFooter = styled.div<{
  withBoxShadow?: boolean;
}>`
  box-shadow: ${({ withBoxShadow }) =>
    withBoxShadow && "inset 0 1px 0 0 #dfe3e8"};
  padding: 16px 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
`;

const ModalCloseButton = ({ onClick, closeIcon }) => {
  return <CloseButton onClick={onClick}>{closeIcon}</CloseButton>;
};

const Header = ({ children, boxShadow }: headerProps) => {
  return <ModalTitle boxShadow={boxShadow}>{children}</ModalTitle>;
};

const Body = ({ children, padding }) => {
  return (
    <ModalBody padding={padding} className="ModalBody">
      {children}
    </ModalBody>
  );
};

const Footer = ({ children, withBoxShadow = true }) => {
  return <ModalFooter withBoxShadow={withBoxShadow}>{children}</ModalFooter>;
};

const Modal: React.FC<ModalProps> & {
  Header?: any;
  Footer?: any;
  Body?: any;
} = (props) => {
  const {
    show,
    onClose,
    children,
    modalWidth = "large",
    noTransition,
    closeOnEscape = true,
    overflow = false,
    closeIcon = <Delete small />,
    closeButtonPosition,
    overLayColor = "rgba(0,0,0,.6)",
    className,
  } = props;
  const isBrowser = useIsBrowser();
  const duration = noTransition ? 0 : 200;
  const isMobile = isBrowser && window?.innerWidth <= 768;

  useEscapeAndStopScrollingEffect({ isOpen: show, onClose, closeOnEscape });

  const ModalComponent = (
    <Transition in={show} timeout={duration} mountOnEnter unmountOnExit>
      {(transitionState) => (
        <>
          {show && (
            <Backdrop
              overLayColor={overLayColor}
              duration={duration}
              style={{
                ...backdropTransitionStyles[transitionState],
              }}
            />
          )}
          <ModalWrapper>
            <ModalContainer
              className={className}
              modalWidth={modalWidth}
              isOverflow={overflow}
              duration={duration}
              style={getModalTransition(isMobile, transitionState)}
              radius={props.radius}
            >
              {onClose && (
                <CloseContainer closeButtonPosition={closeButtonPosition}>
                  <ModalCloseButton onClick={onClose} closeIcon={closeIcon} />
                </CloseContainer>
              )}
              {children}
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
    </Transition>
  );
  if (!isBrowser) {
    return null;
  }
  return (
    <>
      {ReactDOM.createPortal(ModalComponent, document.getElementById("modal")!)}
    </>
  );
};

Modal.displayName = "Modal";
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Header = Header;

export default Modal;
