import { arabHardwareTheme } from "./arabHardwareTheme";
import { blackAndWhiteTheme } from "./blackAndWhiteTheme";
import { modernTheme } from "./modernTheme";

export const theme = {
  isRTL: false,
  colors: {
    primary: "#fe9931",
    secondary: "#969696",
    backgroundReverse: "#f5f7f9",
    white: "#fff",
  },
  fonts: {
    Headers: '"Inter","Almarai", sans-serif',
    Body: '"Inter","Almarai", sans-serif',
  },
  bg: {
    default: "#FFFFFF",
    reverse: "#f5f7f9",
    wash: "#252a31",
    divider: "#F6F7F8",
    border: "#EBECED",
    inactive: "#bac7d5",
    dark: "#E4E4E4",
    gray: "#667085",
  },
  brand: {
    default: "#4f4335",
    alt: "#fe9931",
    wash: "#E8E5FF",
    border: "#DDD9FF",
    dark: "#2A0080",
  },
  space: {
    default: "#0062D6",
    alt: "#1CD2F2",
    wash: "#E5F0FF",
    border: "#e5e5e5",
    dark: "#0F015E",
  },
  special: {
    default: "#E58306",
    alt: "#F1C742",
    dark: "#7D4A00",
    wash: "#FFF5E5",
    border: "#eaeaea",
  },
  success: {
    default: "#00B88B",
    alt: "#00D5BD",
    dark: "#00663C",
    wash: "#D9FFF2",
    border: "#9FF5D9",
    medium: "#42ab44",
  },
  text: {
    default: "#252a31",
    secondary: "#969696",
    alt: "#585757",
    placeholder: "#fe9931",
    reverse: "#FFFFFF",
    inkLight: "#5f738c",
  },
  danger: {
    default: "#B42318",
    alt: "#F04438",
    dark: "#85000C",
    wash: "#FFFBFA",
    border: "#FDA29B",
    orange: "#F05A2A",
  },
  warn: {
    default: "#B54708",
    alt: "#F5A623",
    dark: "#A66B00",
    wash: "#FFFCF5",
    border: "#FEC84B",
  },
  shadow: {
    small: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    medium: "0 2px 4px 0 rgba(0, 0, 0, 0.05)",
    large: "0 4px 8px 0 rgba(0, 0, 0, 0.05)",
    xlarge: "0 8px 16px 0 rgba(0, 0, 0, 0.05)",
    xxlarge: "0 16px 32px 0 rgba(0, 0, 0, 0.05)",
  },
};

export type ThemeType = typeof theme;

export function createTheme(
  colors: any,
  isRTL: boolean,
  fonts: any,
  template: string
): ThemeType {
  if (template === "black-and-white") {
    return blackAndWhiteTheme(colors, isRTL, fonts);
  }

  if (template === "arab-hardware") {
    return arabHardwareTheme(colors, isRTL, fonts);
  }

  if (template === "modern") {
    return modernTheme(colors, isRTL, fonts);
  }

  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...colors,
    },
    fonts,
    isRTL,
  };
}
