import { ThemeType, theme } from ".";

export function blackAndWhiteTheme(
  colors: any,
  isRTL: boolean,
  fonts: any
): ThemeType {
  return {
    ...theme,
    bg: {
      ...theme.bg,
      reverse: "#fafafa",
    },
    text: {
      ...theme.text,
      secondary: "#525252",
    },
    colors: {
      ...theme.colors,
      ...colors,
      backgroundReverse: "#ffffff",
      secondary: "#525252",
    },
    fonts,
    isRTL,
  };
}
