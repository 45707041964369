import { ThemeType, theme } from ".";

export function arabHardwareTheme(
  colors: any,
  isRTL: boolean,
  fonts: any
): ThemeType {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...colors,
      backgroundReverse: "#ffffff",
    },
    fonts,
    isRTL,
  };
}
